export default function getUtmParams() {
  const url = new URLSearchParams(window.location.search);
  const obj = {};
  if (url.get("utm_source")) obj["source"] = url.get("utm_source");
  if (url.get("utm_medium")) obj["medium"] = url.get("utm_medium");
  if (url.get("utm_campaign")) obj["campaign"] = url.get("utm_campaign");
  if (getUrlSearch(url)) obj["url_params"] = getUrlSearch(url);
  if (Object.keys(obj).length === 0) return null;
  return obj;
}

// create an str with all the url params except the utm params, plate and tenant
const getUrlSearch = (url) => {
  const obj = {};
  for (const [key, value] of url) {
    if (["plate", "tenant"].indexOf(key) === -1) {
      obj[key] = value;
    }
  }
  return new URLSearchParams(obj).toString() || null;
};
