import { useEffect } from "react";
import { getTokenAuth } from "../Utils/authenticate";
import Swal from "sweetalert2";

export default function useGetAuthToken(setContext, tenant) {
  useEffect(() => {
    getTokenAuth(tenant)
      .then((res) => {
        setContext((prev) => {
          return {
            ...prev,
            ...res,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oops...",
          text: "Hubo un error en la validación. Por favor, vuelve a intentarlo.",
          icon: "error",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "var(--company-color)",
        }).then(() => {
          window.location.reload();
        });
      });
  }, []);
}
