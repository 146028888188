import { useEffect } from "react";
import { getCompany } from "../Utils/authenticate";

export default function useGetCompany(setContext, tenant) {
  useEffect(() => {
    getCompany(tenant).then((company) => {
      setContext((context) => {
        return {
          ...context,
          company: Array.isArray(company?.result)
            ? company?.result[0]
            : company?.result,
        };
      });
    });
  }, []);
}
