function modifyDate(date,days,months,years,option){
    date.setFullYear(date.getFullYear() + option*years)
    date.setMonth(date.getMonth() + option*months)
    date.setDate(date.getDate() + option*days)
    return date

}

function getAge(birth_date) {
    let today = new Date()
    let age = today.getFullYear() - birth_date.getFullYear()
    let diferenceInMonths = today.getMonth() - birth_date.getMonth()
    if (
      diferenceInMonths < 0 ||
      (diferenceInMonths === 0 && today.getDate() < birth_date.getDate())
    ) {
      age--
    }
    return age
  }

  
  
  function getDateInString(date){
    const year = String(date.getFullYear());
    const month = String(date.getMonth()+1).length == 1 ? "0" + String(date.getMonth()+1): String(date.getMonth()+1)
    const day = String(date.getDate()+1).length == 1 ? "0" + String(date.getDate()+1): String(date.getDate()+1)
    return year + "-" + month + "-" + day
    
  }
  export { modifyDate, getAge ,getDateInString}