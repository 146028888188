import { useEffect } from "react";
import { getOptionalFieldsConfig } from "../Utils/authenticate";

export default function useGetOptionalFields(setContext, tenant) {
  useEffect(() => {
    getOptionalFieldsConfig(tenant).then((res) => {
      setContext((context) => {
        return {
          ...context,
          optionalFields: res,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
