const CITIES_TO_DETELE = ["88001", "88564"]; // san andrés, providencia

export function citiesParser(cities) {
  try {
    return cities.filter(
      (city) =>
        !CITIES_TO_DETELE.includes(city.code?.toString()) &&
        !CITIES_TO_DETELE.includes(city.city_code?.toString())
    );
  } catch (error) {
    return cities;
  }
}
