import * as React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'


function VehiclesFilter (props){
    // Filters
    const [searchLine, setSearchLine] = React.useState(true)
    const [line, setLine] = React.useState("")
    // const [searchFasecolda, setSearchFasecolda] = React.useState(true)
    // const [searchCylinder,setSearchCylinder] = React.useState(false)
    // const [searchPrice, setSearchPrice] = React.useState(false)
    // const [price, setPrice] = React.useState("")
    // const [fasecolda, setFasecolda] = React.useState("")
    // const [cylinder, setCylinder] = React.useState("")

    // When any filter change
    React.useEffect(() => {
        props.setFilterVehicles(
            props.vehicles.filter(vehicle => {
                let ans = true;
                if (searchLine){
                    ans = ans && (vehicle.vehicle.line.toLowerCase().match(line.toLowerCase())? true:false)

                }
                // if (searchFasecolda){
                //     ans = ans && (String(vehicle.vehicle.code).startsWith(fasecolda)? true:false)
                // }
                // if (searchPrice){
                //     ans = ans && (String(vehicle.vehicle.vehicle_risk.reference_price).startsWith(price)? true:false)
                // }
                // if (searchCylinder){
                //     ans = ans && (String(vehicle.vehicle.cylinder).startsWith(cylinder)? true:false)
                // }
                return ans;
            })
        )
    }
        
    ,[line])

    return (
        <React.Fragment>
           <React.Fragment>
            {/* Filters to show */}
                {/* {
                    searchFasecolda
                    ?
                        <Row className="justify-content-center">
                            <Col sm={6} md={7} lg={9}>
                                <Form.Control type={"number"} onChange={(e)=>{setFasecolda(e.target.value)}} placeholder="Cod. Fasecolda" />
                            </Col>
                        </Row>
                    : null
                }
                {
                    searchPrice
                    ?
                        <Row className="justify-content-center">
                            <Col sm={6} md={7} lg={9}>
                                <Form.Control type={"number"} onChange={(e)=>{setPrice(e.target.value)}} placeholder="Precio" />
                            </Col>
                        </Row>
                    : null
                }
                {
                    searchCylinder
                    ?
                        <Row className="justify-content-center">
                            <Col sm={6} md={7} lg={9}>
                                <Form.Control type={"number"} onChange={(e)=>{setCylinder(e.target.value)}} placeholder="Cilindro" />
                            </Col>
                        </Row>
                    : null
                } */}
                {
                    searchLine
                    ?
                        <Row className="justify-content-center">
                            <Col sm={6} md={7} lg={9}>
                                <Form.Control onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={(e)=>{setLine(e.target.value)}} placeholder="Busca tu vehículo" />
                            </Col>
                        </Row>
                    : null
                }

            </React.Fragment>
            {/* Control of filters */}
            <Row className="justify-content-center mt-2">
                <Col xs="auto">
                    <Form.Group >
                        <Form.Check
                            type="checkbox"
                            label="Búsqueda"
                            checked={searchLine}
                            onChange={(e)=>{setSearchLine(!searchLine); setLine("")}}
                        />
                    </Form.Group>
                </Col>
                {/* <Col xs="auto">
                    <Form.Group >
                        <Form.Check
                            type="checkbox"
                            label="Cod. Fasecolda"
                            checked={searchFasecolda}
                            onChange={(e)=>{setSearchFasecolda(!searchFasecolda); setFasecolda("")}}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group >
                        <Form.Check
                            type="checkbox"
                            label="Precio"
                            checked={searchPrice}
                            onChange={(e)=>{setSearchPrice(!searchPrice);setPrice("")}}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group >
                        <Form.Check
                            type="checkbox"
                            label="Cilindraje"
                            checked={searchCylinder}
                            onChange={(e)=>{setSearchCylinder(!searchCylinder);setCylinder("")}}

                        />
                    </Form.Group>
                </Col> */}
            </Row>
        </React.Fragment>
    )
}

export {VehiclesFilter}