import * as React from "react";
import "./Step1.css";
import { Field } from "@progress/kendo-react-form";
import { ComponentsConfig } from "../../../../../Utils/Components/componentsConfig";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  requiredValidator,
  blankSpacesValidator,
  plateValidator,
  useCustomValidator,
} from "../../../../../Utils/validators";
import { ContextData } from "../../../../../Context/Context";

function Step(props) {
  const { formState, setFormState } = props;
  const [inAgency, setInAgency] = React.useState(formState.in_agency || false);
  const { context, setContext } = React.useContext(ContextData);

  const plateCustomValidator = useCustomValidator([
    requiredValidator,
    blankSpacesValidator,
    plateValidator,
  ]);

  return (
    <Container className="mt-5 stepp-1">
      {!inAgency ? (
        <div id="PlateForm">
          <Row>
            <h3 className="text-center mx-auto">
              {context.searchByReference
                ? "Búsqueda por placa"
                : "Búsqueda detallada"}
            </h3>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} sm={6} lg={4}>
              <Field
                className="step1-field-plate"
                label={"*Placa"}
                key={"plate"}
                id={"plate"}
                name={"plate"}
                maxLength="6"
                placeholder={"Ej: XXX999"}
                component={ComponentsConfig.string}
                validator={plateCustomValidator}
              />
            </Col>
          </Row>
          {context.searchByReference ? (
            <Row className="justify-content-center mt-3">
              <Field
                component={ComponentsConfig.line}
                values={{ brand: formState.brand, model: formState.model }}
                validation={requiredValidator}
              />
            </Row>
          ) : null}
        </div>
      ) : (
        <div id="ReferenceForm">
          <Row>
            <h3 className="text-center mx-auto">Búsqueda por referencia</h3>
          </Row>
          <Row className="justify-content-center mt-3">
            <Field
              component={ComponentsConfig.line}
              values={{ brand: formState.brand, model: formState.model }}
              inAgency={inAgency}
              validation={requiredValidator}
            />
          </Row>
        </div>
      )}
      <Row className="justify-content-center">
        <Col md={"auto"}>
          <Field
            key={"in_agency"}
            id={"in_agency"}
            name={"in_agency"}
            component={ComponentsConfig.boolean}
            defaultValue={inAgency}
            label={"0 km?"}
            onChange={(e) => {
              setInAgency(e.target.value);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

const defualtData = {
  in_agency: false,
};

const config = {
  showPrevButton: false,
  showNextButton: true,
};

const header = {
  label: "Placa",
  icon: "k-i-parameters-unknown",
  isValid: undefined,
};

const PropertiesStep1 = {
  component: Step,
  header: header,
  config: config,
  defualtData: defualtData,
};

export { PropertiesStep1 };
