import * as React from "react";
import "./VehicleSelect.css";
import { CardVehicle } from "../Vehicle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { ContextData } from "../../../Context/Context";
import { VehiclesFilter } from "../../Filters/VehicleFilter";

function VehicleSelect(props) {
  const { context, setContext } = React.useContext(ContextData);
  const { vehicles, onChange, onNextStep, onPrevStep, formState } = props;
  const [filterVehicles, setFilterVehicles] = React.useState(vehicles);

  const searchReference = (e) => {
    setContext({ ...context, searchByReference: true });
    props.onPrevStep(e);
  };

  return Array.isArray(vehicles) && vehicles.length > 0 ? (
    <React.Fragment>
      {vehicles.length > 1 ? (
        <VehiclesFilter
          vehicles={vehicles}
          setFilterVehicles={setFilterVehicles}
        />
      ) : null}

      {Array.isArray(filterVehicles) && filterVehicles.length > 0 ? (
        <Row className="justify-content-center mt-5">
          {filterVehicles.map((vehicle, index) => (
            <Col xs={12} md={6} lg={6} key={JSON.stringify(vehicle)}>
              <CardVehicle
                key={JSON.stringify(vehicle)}
                vehicle={vehicle}
                onChange={onChange}
                onNextStep={onNextStep}
                selected={
                  formState.vehicle?.vehicle.code == vehicle.vehicle.code
                    ? true
                    : false
                }
              />
              <br></br>
            </Col>
          ))}
        </Row>
      ) : null}
    </React.Fragment>
  ) : (
    <Row className="justify-content-center mt-3" id="PlateNotFound">
      <Alert variant="danger" className="text-center">
        Lo sentimos no pudimos encontrar tu vehículo, por favor, si los datos no
        son reales, ve a{" "}
        {
          <a href="#" onClick={props.onPrevStep}>
            modificarlos
          </a>
        }
        ,si los datos son correctos te ayudaremos a{" "}
        {
          <a href="#" onClick={searchReference}>
            encontrar tu vehículo
          </a>
        }
      </Alert>
    </Row>
  );
}

export { VehicleSelect };
