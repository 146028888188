export function getDateObject(stringDate){
    if(stringDate){
        let dateValues = stringDate.split("-");
        let date = new Date();
        date.setDate(parseInt(dateValues[2]));
        date.setMonth(parseInt(dateValues[1])-1);
        date.setFullYear(parseInt(dateValues[0]));
        return date;
    }
    return "";
}