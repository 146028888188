import { FetchInfo } from "./FetchInfo";

const getEventName = (context, quotationData) => {
  const tenantName = context?.tenant?.replace(".co.agentemotor.com", "") || "";
  const business_line = quotationData?.data?.business_line || "";
  const objIdentification =
    business_line === "vehiculos"
      ? quotationData?.data?.insurable_objects?.[0]?.identification ||
        "Sin_Placa"
      : business_line === "hogar"
      ? quotationData?.data?.insurable_objects?.[0]?.structure?.address_line1 ||
        "Sin_Dirección"
      : business_line === "vida" || business_line === "life"
      ? quotationData?.data?.insurable_objects?.[0]?.life?.insurance_use ||
        "Uso_no_definido"
      : "Sin_Identificación";
  const eventName =
    `${tenantName}_${business_line}_${objIdentification}`.replace(
      /[^A-Za-z0-9_]/g,
      ""
    );
  return eventName;
};

export const submitRisk = (quotationData, context, setContext) => {
  const quoteData = {
    input: JSON.stringify(quotationData),
    name: getEventName(context, quotationData),
    stateMachineArn:
      "arn:aws:states:us-east-2:907888255793:stateMachine:EventRequestPolicy",
  };
  const headers = { Authorization: context.id_token };

  const redirectPage = context.redirectPage;
  const useMessageRedirect = context.useMessageRedirect;

  FetchInfo(
    "https://47kw7otdmc.execute-api.us-east-2.amazonaws.com/prod/orquestator/syncstart",
    quoteData,
    headers
  )
    .then((res) => {
      console.log(res);
      var output = JSON.parse(res.output);
      console.log(output);
      var infoOpportunity = output[1] || output.id;
      var id_oportunity = infoOpportunity.id?.split("-")[0];
      if (!redirectPage) {
        useMessageRedirect
          ? window.parent.postMessage(
              `cambiarURL:cotizaciones?id_oportunity=${id_oportunity}&tenant=${context.tenant}&access_token=${infoOpportunity.access_token}`,
              "*"
            )
          : window.location.replace(
              `cotizaciones?id_oportunity=${id_oportunity}&tenant=${context.tenant}&access_token=${infoOpportunity.access_token}`
            );
      } else {
        useMessageRedirect
          ? window.parent.postMessage(
              `cambiarURL:${redirectPage}?id_oportunity=${id_oportunity}&tenant=${context.tenant}&access_token=${infoOpportunity.access_token}`,
              "*"
            )
          : (window.location.href = `${redirectPage}?id_oportunity=${id_oportunity}&tenant=${context.tenant}&access_token=${infoOpportunity.access_token}`);
      }
    })
    .catch((err) => {
      console.log(err);
      setContext((prevState) => ({ ...prevState, submitError: true }));
    });
};
