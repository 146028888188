import "./Assets/Styles/agm_kendo_bck_v1_2.css";
import "./App.css";
import React from "react";
import { FormStepper } from "./Components/Forms/Stepper/FormStepper";
import { ContextData } from "./Context/Context";
import RingLoader from "react-spinners/RingLoader";
import { Route, Switch } from "react-router-dom";
import Row from "react-bootstrap/Row";
import getTenantByParam from "./Utils/getTenantByParam";
import EmbebedComplements from "./Components/Others/EmbebedComplements";
import getUtmParams from "./Utils/getUtmParams";
import getRedirectPage from "./Utils/getRedirectPage";
import useAutoCaptchaVerify from "./Hooks/useAutoCaptchaVerify";
import getAsesorMailByUrlParam from "./Utils/getAsesorMail";
import { ModalInformation } from "./Components/Others/PopUpComunication";
import useGetCompany from "./Hooks/useGetCompany";
import useGetPoliticsLink from "./Hooks/useGetPoliticsLink";
import useGetAuthToken from "./Hooks/useGetAuthToken";
import getUseMessageRedirect from "./Utils/getUseMessageRedirect";
import useGetOptionalFields from "./Hooks/useGetOptionalFields";
import useGetClientIp from "./Hooks/useGetClientIp";

function App({ origin }) {
  const url = new URLSearchParams(window.location.search);
  const tenant = React.useMemo(
    () =>
      origin === "AGMroot"
        ? getTenantByParam()
        : window.odoo?.session_info?.db ||
          url.get("tenant") ||
          window.location.host,
    [origin]
  );
  const [context, setContext] = React.useState({
    searchURL: window.location.search,
  });
  const colors = React.useMemo(() => {
    return {
      primary: "#4800d1",
      company: "#084c8b",
      header: "#084c8b",
      table: "#686868",
      offer: "#686868",
      title: "#084c8b",
      gray: "#585858",
      fav: "rgba(0,128,0)",
      favMess: "rgba(0,128,0)",
      price: "rgba(32, 132, 227, 1)",
    };
  }, []);

  React.useEffect(() => {
    const utmParams = getUtmParams();
    const redirectPage = getRedirectPage(origin, tenant);
    const asesorMail = getAsesorMailByUrlParam(origin);
    const useMessageRedirect = getUseMessageRedirect(origin);

    setContext((prev) => {
      return {
        ...prev,
        tenant: tenant,
        utmParams: utmParams,
        origin: origin,
        redirectPage: redirectPage,
        asesorMail: asesorMail,
        useMessageRedirect: useMessageRedirect,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetCompany(setContext, tenant);
  useGetPoliticsLink(setContext, tenant);
  useGetAuthToken(setContext, tenant);
  useGetOptionalFields(setContext, tenant);
  useGetClientIp(setContext);
  // useAutoCaptchaVerify(tenant);

  return (
    <ContextData.Provider value={{ context: context, setContext: setContext }}>
      <ModalInformation
        show={context.submitError}
        title={"Hay problemas internos"}
        body={"Comuníquese con su asesor"}
      />
      <style type="text/css">
        {`\
            :root {\
              --primary-color: ${colors["primary"]};\
              --company-color: ${colors["company"]};\
              --header-color: ${colors["header"]};\
              --table-color: ${colors["table"]};\
              --offer-color: ${colors["offer"]};\
              --title-color: ${colors["title"]};\
              --gray-color: ${colors["gray"]};\
              --fav-color: ${colors["fav"]};\
              --fav-mess-color: ${colors["favMess"]};\
              --price-color: ${colors["price"]};\
            }\
            `}
      </style>
      {origin === "AGMroot" && <EmbebedComplements />}
      {context?.origin ? (
        <Switch>
          <Route exact path="*/:resume?" component={FormStepper} />
        </Switch>
      ) : (
        <Row className="justify-content-center mt-3">
          <RingLoader color="var(--company-color)"></RingLoader>
        </Row>
      )}
    </ContextData.Provider>
  );
}

export default App;
