import * as React from "react";
import "./Step4.css";
import { Field } from "@progress/kendo-react-form";
import { ComponentsConfig } from "../../../../../Utils/Components/componentsConfig";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { modifyDate, getDateInString } from "../../../../../Utils/others";
import {
  GENDERS,
  IDENTIFICATIONS_TYPES,
  OCUPATIONS,
  IDENTIFICATIONS_TYPES_PERSON,
  MARITAL_STATUS,
} from "../../../../../Utils/definitions";
import {
  requiredValidator,
  emailValidator,
  phoneValidator,
  nitValidator,
  ageStringValidator,
  blankSpacesValidator,
  useCustomValidator,
} from "../../../../../Utils/validators";
import { ContextData } from "../../../../../Context/Context";
import {
  STATICPOLITICSLINK,
  STATICTERMSLINK,
} from "../../../../../Utils/variables";
import ReCaptchaV2 from "../../../../ReCaptchaV2/ReCaptchaV2";

function Step(props) {
  const { formState, setFormState } = props;
  const { context } = React.useContext(ContextData);

  const [identificationType, setIdentificationType] = React.useState(
    formState.identification_type?.id || "CC"
  );

  // validators

  const companyNameValidator = useCustomValidator([
    requiredValidator,
    blankSpacesValidator,
  ]);
  const representationIdentificationType = useCustomValidator([
    requiredValidator,
  ]);
  const representationIdentificationNumber = useCustomValidator([
    requiredValidator,
  ]);
  const insuredAddressValidator = useCustomValidator([
    requiredValidator,
    blankSpacesValidator,
  ]);

  //optional fields
  const optionalFields = React.useMemo(
    () => context.optionalFields || {},
    [context]
  );

  return (
    <Container
      className="mt-5"
      id={identificationType == "NIT" ? "CompanyDataForm" : "PersonDataForm"}
    >
      <Row className="justify-content-center">
        <h3 className="text-center">Información del propietario</h3>
      </Row>

      <Row className="justify-content-center mt-3" id="DocumentForm">
        <Col md={6}>
          <Field
            key={"identification_type"}
            id={"identification_type"}
            name={"identification_type"}
            component={ComponentsConfig.select}
            label={"*Tipo de documento"}
            validator={useCustomValidator([requiredValidator])}
            data={IDENTIFICATIONS_TYPES}
            onChange={(event) => {
              setIdentificationType(event.target.value.id);
            }}
          />
        </Col>
        <Col md={6}>
          <Field
            // className = "step4-identification-number"
            key={"identification_number"}
            id={"identification_number"}
            name={"identification_number"}
            hint={
              identificationType == "NIT"
                ? "Ingresa el número sin guiones y con el dígito de verificación"
                : ""
            }
            maxLength="10"
            validator={useCustomValidator(
              identificationType === "NIT"
                ? [requiredValidator, nitValidator]
                : [requiredValidator],
              [identificationType]
            )}
            component={ComponentsConfig.strnumber}
            label={"*Número de documento"}
          />
        </Col>
      </Row>
      {identificationType == "NIT" ? (
        <React.Fragment>
          <Row className="mt-4 mx-auto">
            <Col>
              <h5>Empresa</h5>
            </Col>
          </Row>

          <Row className="justify-content-center mt-2">
            <Col>
              <Field
                key={"company_name"}
                id={"company_name"}
                name={"company_name"}
                validator={companyNameValidator}
                component={ComponentsConfig.string}
                label={"*Nombre de empresa"}
              />
            </Col>
          </Row>

          <Row className="mt-4 mx-auto">
            <Col>
              <h5>Representante Legal</h5>
            </Col>
          </Row>

          <Row className="justify-content-center mt-3">
            <Col md={6}>
              <Field
                key={"representantive_identification_type"}
                id={"representantive_identification_type"}
                name={"representantive_identification_type"}
                validator={representationIdentificationType}
                component={ComponentsConfig.select}
                label={"*Tipo de documento del representante"}
                data={IDENTIFICATIONS_TYPES_PERSON}
              />
            </Col>
            <Col md={6}>
              <Field
                key={"representantive_identification_number"}
                id={"representantive_identification_number"}
                name={"representantive_identification_number"}
                maxLength="10"
                validator={representationIdentificationNumber}
                component={ComponentsConfig.strnumber}
                label={"*Número de documento del representante"}
              />
            </Col>
          </Row>
        </React.Fragment>
      ) : null}

      <Row className="justify-content-center">
        <Col md={6}>
          <Field
            key={"first_name"}
            id={"first_name"}
            name={"first_name"}
            maxLength="50"
            validator={useCustomValidator([
              requiredValidator,
              blankSpacesValidator,
            ])}
            component={ComponentsConfig.string}
            label={"*Nombres"}
          />
        </Col>
        <Col md={6}>
          <Field
            key={"last_name"}
            id={"last_name"}
            name={"last_name"}
            maxLength="50"
            validator={useCustomValidator([
              requiredValidator,
              blankSpacesValidator,
            ])}
            component={ComponentsConfig.string}
            label={"*Apellidos"}
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6}>
          <Field
            className="step1-field-plate k-input k-input-md k-rounded-md k-input-solid"
            label={"*Fecha de nacimiento"}
            key={"birth_date"}
            id={"birth_date"}
            name={"birth_date"}
            max={getDateInString(modifyDate(new Date(), 0, 0, 18, -1))}
            min={getDateInString(modifyDate(new Date(), 0, 0, 100, -1))}
            component={ComponentsConfig.dateAuto}
            validator={useCustomValidator([
              requiredValidator,
              ageStringValidator,
            ])}
          />
        </Col>
        <Col md={6}>
          <Field
            key={"gender"}
            id={"gender"}
            name={"gender"}
            validator={useCustomValidator([requiredValidator])}
            component={ComponentsConfig.radio}
            label={"*Género"}
            data={GENDERS}
            layout={"horizontal"}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Field
            key={"occupation"}
            id={"occupation"}
            name={"occupation"}
            validator={useCustomValidator([requiredValidator])}
            component={ComponentsConfig.select}
            label={"*Ocupación"}
            data={OCUPATIONS}
          />
        </Col>
        <Col md={6}>
          <Field
            key={"marital_status"}
            id={"marital_status"}
            name={"marital_status"}
            validator={useCustomValidator([requiredValidator])}
            component={ComponentsConfig.select}
            label={"*Estado civil"}
            data={MARITAL_STATUS}
          />
        </Col>
      </Row>

      <Row>
        {optionalFields?.id_expedition_date && (
          <Col md={6}>
            <Field
              className="step1-field-plate k-input k-input-md k-rounded-md k-input-solid"
              key={"id_expedition_date"}
              id={"id_expedition_date"}
              name={"id_expedition_date"}
              component={ComponentsConfig.dateAuto}
              label={"*Fecha de expedición del documento"}
            />
          </Col>
        )}
        {optionalFields?.insured_address && (
          <Col md={6}>
            <Field
              key={"insured_address"}
              id={"insured_address"}
              name={"insured_address"}
              maxLength="50"
              validator={insuredAddressValidator}
              component={ComponentsConfig.string}
              label={"*Dirección de residencia"}
            />
          </Col>
        )}
      </Row>

      <Row className="mt-4 mx-auto">
        <Col>
          <h5 className="step4-contact-info-header mt-3">
            Información de contacto
          </h5>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6}>
          <Field
            key={"phone"}
            id={"phone"}
            name={"phone"}
            maxLength="10"
            placeholder="Ej: 3001234567"
            validator={useCustomValidator([requiredValidator, phoneValidator])}
            component={ComponentsConfig.phone}
            label={"*Número telefónico"}
          />
        </Col>
        <Col md={6}>
          <Field
            key={"email"}
            id={"email"}
            validator={useCustomValidator([requiredValidator, emailValidator])}
            name={"email"}
            maxLength="60"
            placeholder="Ej: nombre@correo.com"
            component={ComponentsConfig.string}
            label={"*Correo"}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col md={6} className="" align="center">
          <Field
            key={"agree_terms"}
            id={"agree_terms"}
            name={"agree_terms"}
            validator={useCustomValidator([requiredValidator])}
            component={ComponentsConfig.boolean}
            label={
              <span>
                Acepto los{" "}
                <a
                  id="terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={context.termsLink || STATICTERMSLINK}
                >
                  términos y condiciones
                </a>
                , así como el{" "}
                <a
                  id="terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={context.politicsLink || STATICPOLITICSLINK}
                >
                  tratamiento de datos personales
                </a>
                .{" "}
              </span>
            }
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col md={6} className="" align="center">
          <ReCaptchaV2 />
        </Col>
      </Row>
    </Container>
  );
}

const defualtData = {
  identification_type: IDENTIFICATIONS_TYPES[0],
};
const config = {
  showPrevButton: true,
  showNextButton: true,
};

const header = {
  label: "Propietario",
  icon: "k-i-user",
  isValid: undefined,
};

const PropertiesStep4 = {
  component: Step,
  header: header,
  config: config,
  defualtData: defualtData,
};

export { PropertiesStep4 };
