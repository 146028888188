import axios from "axios";

export const FetchInfo = async (url, data, headers) => {

    const raw = data;

    return await axios.post(
        url,
        raw,
        {headers}
                
    )
    .then((res) => res.data)
    .catch(e => e)
}