import * as React from 'react';
import './Step2.css';



function Step (props) {
    const {formState, setFormState} = props;
    return(
        <React.Fragment>

        </React.Fragment>
    )
}


  
const PropertiesStep2 = {
    component : Step,
};


export { PropertiesStep2 };