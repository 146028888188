import React from "react";
import useVerifyReCaptcha from "./useVerifyReCaptcha";
import Swal from "sweetalert2";

const allowedBotDomains = ["zalabin.com"];
const passTroughTenants = ["howdenwacolda.co.agentemotor.com"];
const threshold = allowedBotDomains.some((domain) =>
  window.location.href.includes(domain)
)
  ? 0
  : 0.3;

export default function useAutoCaptchaVerify(tenant) {
  const { handleReCaptchaVerify, executeRecaptcha } = useVerifyReCaptcha();
  const [isBot, setIsBot] = React.useState(false);

  React.useEffect(() => {
    if (executeRecaptcha) {
      async function verify() {
        if (passTroughTenants.some((domain) => tenant?.includes(domain))) {
          setIsBot(false);
          return;
        }

        let captchaRes = null;
        try {
          captchaRes = await handleReCaptchaVerify();
        } catch (err) {
          console.log(err);
        }
        if (captchaRes?.success && captchaRes?.score > threshold) {
          setIsBot(false);
        } else {
          setIsBot(true);
        }
      }
      verify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  React.useEffect(() => {
    //Mostrar modal de error de validación de captcha y recargar la página
    if (isBot) {
      Swal.fire({
        title: "Oops...",
        text: "Hubo un error en la validación. Por favor, asegúrate de que no eres un robot y vuelve a intentarlo.",
        icon: "error",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "var(--company-color)",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [isBot]);
}
