export const parseTermsLogs = (data, context) => {
  const date = new Date().toISOString();
  const terms_logs = [
    {
      date,
      event: "terms",
      ip: context.clientIp,
      link: context.termsLink,
      origin: "formWeb",
      name: data.first_name + " " + data.last_name,
      identification_number:
        data.identification_type.id !== "NIT"
          ? data.identification_number
          : data.representantive_identification_number,
      identification_type:
        data.identification_type.id !== "NIT"
          ? data.identification_type.id
          : data.representantive_identification_type.id,
      email: data.email,
      phone: data.phone,
    },
    {
      date,
      event: "politics",
      ip: context.clientIp,
      link: context.politicsLink,
      origin: "formWeb",
      name: data.first_name + " " + data.last_name,
      identification_number:
        data.identification_type.id !== "NIT"
          ? data.identification_number
          : data.representantive_identification_number,
      identification_type:
        data.identification_type.id !== "NIT"
          ? data.identification_type.id
          : data.representantive_identification_type.id,
      email: data.email,
      phone: data.phone,
    },
  ];
  return terms_logs;
};
