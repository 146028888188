import { getter } from "@progress/kendo-react-common";
import { getAge } from "./others";
import { getDateObject } from "./getDateObject";
import React from "react";

const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
const phoneRegex = new RegExp(/^[\+]?[0-9]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const onlyLettersAndSpacesRegex = new RegExp(/^[a-zA-Z ]+$/);
const onlyNumbersRegex = new RegExp(/^[0-9]+$/);
const identificationRegex = new RegExp(/^[0-9]+(-{1}[0-9]{1})?$/);
const plateRegex = new RegExp(/^\w{3}\d{2,3}\w{0,1}/);
const termsValidator = (value) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
const emailValidator = (value) =>
  !value
    ? "El Email es Obligatorio"
    : emailRegex.test(value)
    ? ""
    : "El formato de Email no es valido";
const nameValidator = (value) =>
  !value
    ? "Full Name is required"
    : value.length < 7
    ? "Full Name should be at least 7 characters long."
    : "";
const userNameValidator = (value) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 5 characters long."
    : "";
const phoneValidator = (value) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
    ? ""
    : "El numero telefonico no es valido";
const cardValidator = (value) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";
const cvcValidator = (value) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";
const plateValidator = (value) =>
  !value
    ? "¡Este campo es obligatorio!"
    : plateRegex.test(value)
    ? ""
    : "Debe tener formato de placa";
const guestsValidator = (value) =>
  !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
const nightsValidator = (value) =>
  value ? "" : "Number of Nights is required.";
const arrivalDateValidator = (value) =>
  value ? "" : "Arrival Date is required.";
const colorValidator = (value) => (value ? "" : "Color is required.");
const requiredValidator = (value) =>
  value ? "" : "¡Este campo es obligatorio!";
const requiredNotNullId = (value) =>
  value?.id ? "" : "¡Este campo es obligatorio!";
const blankSpacesValidator = (value) =>
  value.replace(/ /g, "") ? "" : "¡Este campo es obligatorio!";
const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Password must be at least 8 symbols.";
const addressValidator = (value) => (value ? "" : "Address is required.");
const onlyLettersValidator = (value) =>
  !value
    ? "Este campo es requerido"
    : onlyLettersAndSpacesRegex.test(value)
    ? ""
    : "Entrada invalida";
const onlyNumbersValidator = (value) =>
  value === undefined || value === null
    ? "Este campo es requerido"
    : onlyNumbersRegex.test(value)
    ? ""
    : "Entrada invalida";
const identificationValidator = (value) =>
  !value
    ? "Este campo es requerido"
    : identificationRegex.test(value)
    ? ""
    : "Entrada invalida";
const nitValidator = (value) => (value?.length == 10 ? "" : "NIT inválido");
const ageValidator = (value) =>
  !value
    ? "Este campo es requerido"
    : getAge(value) >= 18
    ? ""
    : "Fecha no permitida,debe ser mayor de edad";
const ageStringValidator = (value) =>
  !value
    ? "Este campo es requerido"
    : getAge(getDateObject(value)) >= 18 && getAge(getDateObject(value)) <= 100
    ? ""
    : "Fecha no permitida";
const userNameGetter = getter("username");
const emailGetter = getter("email");

const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Please fill in the following fields.",
    ["username"]: !userName ? "User Name is required." : "",
    ["email"]:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in a valid format.",
  };
};

const useCustomValidator = (customValidators = [], dependences = []) => {
  return React.useMemo(() => {
    return customValidators ? [...customValidators] : [];
  }, [...dependences]);
};

export {
  termsValidator,
  emailValidator,
  nameValidator,
  userNameValidator,
  phoneValidator,
  cardValidator,
  cvcValidator,
  plateValidator,
  guestsValidator,
  nightsValidator,
  useCustomValidator,
  arrivalDateValidator,
  colorValidator,
  requiredValidator,
  passwordValidator,
  addressValidator,
  formValidator,
  onlyLettersValidator,
  blankSpacesValidator,
  onlyNumbersValidator,
  identificationValidator,
  ageValidator,
  nitValidator,
  ageStringValidator,
  requiredNotNullId,
};
