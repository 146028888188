import { defaultDataByParameters } from "../../../../Utils/transformDefaultData"
import { getInitialStep } from '../../../../Utils/getInitialStep'
import { transformStepHeaders } from '../../../../Utils/transformStepHeaders'




import { PropertiesStep1 } from "./Step1"
import { PropertiesStep2 } from "./Step2"
import { PropertiesStep3 } from "./Step3"
import { PropertiesStep4 } from "./Step4"


const stepsToRender = [ 
    PropertiesStep1 , 
    PropertiesStep2 , 
    PropertiesStep3 , 
    PropertiesStep4 ,
]

const initialStep = getInitialStep()

const stepPages = stepsToRender.map((step)=>(step.component))

const stepsHeaders =  transformStepHeaders(stepsToRender.map((step)=>(step.header)),initialStep)

const stepsConfig = stepsToRender.map((step)=>(step.config))

const defaultData = defaultDataByParameters(stepsToRender.map((step) => (step.defualtData)).reduce((a, v) => ({ ...a,...v}), {}))




export { stepsHeaders, stepPages, stepsConfig, defaultData, initialStep }