import * as React from 'react';
import './Step1.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'


function Step (props) {
    const {formState,setFormState} = props;
    const [inAgency,setInAgency] = React.useState(formState.in_agency||false);

    return(
        <React.Fragment>
            <Row>
                <h3 className="title-section text-center mx-auto">Información de vehículo</h3>
            </Row>
            <br></br>
            {
            !inAgency?
                <React.Fragment>
                    
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label>Placa</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.plate.toUpperCase()} />
                        </Col>
                    </Row>
                    <br></br>
                </React.Fragment>
            :
                <React.Fragment>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label>Marca</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.vehicle.vehicle.brand} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label>Modelo</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.vehicle.vehicle.model} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label>Línea</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.vehicle.vehicle.codification.line1} />
                        </Col>
                    </Row>
                    <br></br>


                </React.Fragment>
            }
            {/* <br></br> */}
            {/* <Row className="justify-content-center">
                <Col xs = {"auto"}>
                    <Form.Label>Tengo la placa</Form.Label>
                </Col>
                <Col >
                    <Form.Control disabled={true} value={formState.in_agency} />
                </Col>
                
            </Row> */}
        </React.Fragment>
        
    );
}

const PropertiesStep1 = {
    component : Step,
};


export { PropertiesStep1 };