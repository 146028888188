import CryptoJS from 'crypto-js';

const key = "Agm#AGM!"

export default function getTenantByParam(){
    const root = document.getElementById("AGMroot")
    const code = root.getAttribute("tenant")

    //! desencriptar el tenant y retornarlo
    const bytes = CryptoJS.AES.decrypt(code, key);
    let tenant = bytes.toString(CryptoJS.enc.Utf8);
    console.log("tenant: ", tenant);
    
    return tenant
}