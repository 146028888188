import {
    FormInput,
    FormNumericTextBox,
    FormDropDownList,
    FormAutoComplete,
    FormDatePicker,
    FormTimePicker,
    FormDateTimePicker,
    FormTextArea,
    FormRadioGroup,
    FormCheckbox,
    FormArray,
    FormObject,
    AgmUbication,
    AgmReferenceFields,   
    FormNumericTextBoxString,
    FormPhoneInput,
    FormDatePickerAuto
} from './formComponents'

export const ComponentsConfig = {
    string: FormInput,
    text: FormInput,
    number: FormNumericTextBox,
    autocomplete: AgmUbication,
    integer: FormNumericTextBox,
    boolean: FormCheckbox,
    object: FormObject,
    line: AgmReferenceFields,
    array: FormArray,
    date: FormDatePicker,
    time: FormTimePicker,
    datetime: FormDateTimePicker,
    textarea: FormTextArea,
    select: FormDropDownList,
    radio: FormRadioGroup,
    checkbox: FormCheckbox,  
    strnumber: FormNumericTextBoxString,
    phone: FormPhoneInput,
    dateAuto : FormDatePickerAuto
}