import React from 'react'

export default function EmbebedComplements() {
  return <>
    <link rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons"/>
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&amp;display=swap"
      rel="stylesheet"/>
    <link href="https://fonts.googleapis.com/css2?family=Numans&amp;display=swap" rel="stylesheet"/>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossOrigin="anonymous"/>
    <link href="https://use.fontawesome.com/releases/v5.0.10/css/all.css" rel="stylesheet" />
    </>
  
}
