import { useEffect } from "react";
import { getPoliticsLink } from "../Utils/authenticate";
import { STATICPOLITICSLINK, STATICTERMSLINK } from "../Utils/variables";

export default function useGetPoliticsLink(setContext, tenant) {
  useEffect(() => {
    getPoliticsLink(tenant)
      .then((res) => {
        const politicsLink =
          res && res.politics_link ? res.politics_link : STATICPOLITICSLINK;
        const termsLink =
          res && res.terms_link ? res.terms_link : STATICTERMSLINK;
        setContext((context) => {
          return {
            ...context,
            politicsLink: politicsLink,
            termsLink: termsLink,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        setContext((context) => {
          return {
            ...context,
            politicsLink: STATICPOLITICSLINK,
            termsLink: STATICTERMSLINK,
          };
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
