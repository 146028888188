import * as React from 'react';
import "./Step4.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import { getAge } from '../../../../../Utils/others'
import { getDateObject } from '../../../../../Utils/getDateObject'



function Step (props) {
    const {formState, setFormState} = props;

    const [identificationType,setIdentificationType] = React.useState( formState.identification_type?.id || "CC");
    return(
        <React.Fragment >

            <Row className="justify-content-center mt-5">
                <h3 className='title-section text-center'>Información del propietario</h3>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Tipo de documento</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.identification_type.text} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                
                <Col >
                    <Form.Label>Número de documento</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9} >
                    <Form.Control disabled={true} value={formState.identification_number} />
                </Col>
                
            </Row>
            <br></br>

            {
                identificationType == "NIT" ?
                <React.Fragment>

                    <Row >
                        <Col>
                            <h5>Empresa</h5>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col >
                            <Form.Label>Nombre de empresa</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9} >
                            <Form.Control disabled={true} value={formState.company_name} />
                        </Col>
                        
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            <h5>Representante Legal</h5>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col >
                            <Form.Label>Tipo de documento</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.representantive_identification_type.text} />
                        </Col> 
                    </Row>
                    <br></br>

                    <Row className="justify-content-center">
                        <Col  >
                            <Form.Label>Número de documento</Form.Label>
                        </Col>
                        <Col sm={6} md={7} lg={9}>
                            <Form.Control disabled={true} value={formState.representantive_identification_number} />
                        </Col>
                        
                    </Row>
                    <br></br>


                </React.Fragment>
                
                :null
            }
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Nombres</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.first_name} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col  >
                    <Form.Label>Apellidos</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.last_name} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Fecha de nacimiento</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    {/* <Form.Control  disabled={true} value={
                        formState.birth_date.getDate() + '/' + (formState.birth_date.getMonth()+1) + '/' + formState.birth_date.getFullYear() 

                        } /> */}
                    <Form.Control  disabled={true} value={formState.birth_date
                        // getDateObject(formState.birth_date).getDate() + '/' + (getDateObject(formState.birth_date).getMonth()+1) + '/' + getDateObject(formState.birth_date).getFullYear() 

                        } />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Edad</Form.Label>
                </Col>
                {/* <Col sm={6} md={7} lg={9}>
                    <Form.Control  disabled={true} value={getAge(formState.birth_date)} />
                </Col> */}
                <Col sm={6} md={7} lg={9}>
                    <Form.Control  disabled={true} value={getAge(getDateObject(formState.birth_date))} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                
                <Col >
                    <Form.Label>Género</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.gender =="M" ? "Masculino" : "Femenino"} />
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col >
                    <Form.Label>Ocupación</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.occupation.text} />
                </Col>

            </Row>
            <br></br>
            <Row>
                <Col >
                    <Form.Label>Estado civil</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.marital_status.text} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Número telefónico</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.phone} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Correo</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.email} />
                </Col>
            </Row>
        </React.Fragment>
    )

}


const PropertiesStep4 = {
    component : Step,
}


export { PropertiesStep4 }