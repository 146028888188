import axios from "axios";

const axiosRetry = () => {
  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        // console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
};

axiosRetry();

export const getTokenAuth = async (tenant) => {
  return await axios
    .post(
      "https://e0rx3hrb03.execute-api.us-east-2.amazonaws.com/default/authenticate",
      {
        tenant: tenant,
        username: "public",
        password: "public",
      },
      {
        retry: 3,
        retryDelay: 1000,
      }
    )
    .then((res) => {
      if (res?.data?.errorMessage) {
        throw new Error(res?.data?.errorMessage);
      }
      return res.data;
    });
};

export const getCompany = async (tenant) => {
  const body = {
    jsonrpc: "2.0",
    method: "call",
    id: 1,
    params: {
      model: "res.company",
      method: "read",
      args: [
        [window.odoo?.session_info?.website_company_id || 1],
        ["name", "vat"],
      ],
      kwargs: {},
    },
  };

  return await axios
    .post(`https://${tenant}/web/dataset/get_company`, body, {
      retry: 5,
      retryDelay: 1000,
    })
    // .post(newEndpoint ? `https://${tenant}/web/dataset/get_company`: `https://${tenant}/web/dataset/call_kw`, body)
    // .post(`http://localhost:8069/web/dataset/get_company`, body)
    .then((res) => {
      // console.log(res)
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
      // return {
      //   "jsonrpc": "2.0",
      //   "id": 1,
      //   "result": [
      //     {
      //       "id": 1,
      //       "name": "Seguros Cencosud",
      //       "vat": "9001235647"
      //     }
      //   ]
      // }
    });
};

export const getPoliticsLink = async (tenant) => {
  const origin = window.location.origin.includes("localhost")
    ? "http://localhost:8069"
    : `https://${tenant}`;

  try {
    const res = await axios.get(
      `${origin}/configuraciones/get_politics_terms`,
      {
        retry: 3,
        retryDelay: 1000,
      }
    );
    return res.data;
  } catch (error) {
    return false;
  }
};

export const getOptionalFieldsConfig = async (tenant) => {
  const origin = window.location.origin.includes("localhost")
    ? "http://localhost:8069"
    : `https://${tenant}`;

  return await axios
    .get(`${origin}/configuraciones/get_optional_fields_client`, {
      retry: 3,
      retryDelay: 1000,
    })
    .then((res) => res.data);
};

export const getClientIp = async () => {
  try {
    const res = await axios.get(
      "https://6c11c7i3cb.execute-api.us-east-2.amazonaws.com/v1/utils/v1/get-client-ip"
    );
    return res.data?.ip_address || false;
  } catch (error) {
    return false;
  }
};
