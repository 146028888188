import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalInformation(props) {
  const onRedirect = () => {
    window.location.reload();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          align="center"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p align="center">{props.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onRedirect}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export { ModalInformation };
