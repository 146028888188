import * as React from 'react';
import "./Step3.css"
import { Field } from "@progress/kendo-react-form";
import { ComponentsConfig } from "../../../../../Utils/Components/componentsConfig";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PLATE_TYPES_BY_VEHICLE_TYPE, ALL_PLATE_TYPES, USE_TYPES_BY_PLATE_PARTICULAR, USE_TYPES_BY_PLATE_PUBLIC, searchDefinitionById } from '../../../../../Utils/definitions';
import { requiredValidator, onlyNumbersValidator, requiredNotNullId, useCustomValidator } from '../../../../../Utils/validators'


function Step (props) {
    const {formState,setFormState, formRenderProps} = props;
    const [plateType,setPlateType] = React.useState(formState.plate_type ||  searchDefinitionById(formState.vehicle?.vehicle.vehicle_risk.plate_type,ALL_PLATE_TYPES))
    const handlePlateChange = React.useCallback((e) => {
        setPlateType(e.target.value)
        formRenderProps.onChange("use_type",{
            value:{
                text: "",
                id: null
            }
        })
    },[])

    return(
        <Container className='mt-5' id="VehicleRiskForm">
            <Row className="justify-content-center">
                <h3 className="text-center">Información adicional de vehículo</h3>
            </Row>
            <Row className="justify-content-center mt-5">
                <Col md = {6}>
                    <Field
                        key={"plate_type"}
                        id={"plate_type"}
                        name={"plate_type"}
                        component={ComponentsConfig.select}
                        defaultValue={plateType}
                        label = {"*Tipo de placa"}
                        data={PLATE_TYPES_BY_VEHICLE_TYPE[formState.vehicle.vehicle.type]}
                        disabled = {PLATE_TYPES_BY_VEHICLE_TYPE[formState.vehicle.vehicle.type]?.length <=1}
                        validator={useCustomValidator([requiredValidator])}
                        onChange = {handlePlateChange}
                    />
                </Col>
                <Col md = {6}>
                    <Field
                        key={"use_type"}
                        id={"use_type"}
                        name={"use_type"}
                        component={ComponentsConfig.select}
                        label = {"*Tipo de uso"}
                        data={plateType?.id === "particular"?  USE_TYPES_BY_PLATE_PARTICULAR[formState.vehicle.vehicle.type] : USE_TYPES_BY_PLATE_PUBLIC[formState.vehicle.vehicle.type]}
                        validator={useCustomValidator([requiredNotNullId]) }
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md = {6}>
                    <Field
                        key={"accesories_price"}
                        id={"accesories_price"}
                        name={"accesories_price"}
                        component={ComponentsConfig.number}
                        label = {"Valor adicional en accesorios"}
                        hint={"Si no tiene accesorios, déjalo en 0"}
                    />
                </Col>
                <Col md = {6}>
                    <Field
                        key={"ubication"}
                        id={"ubication"}
                        name={"ubication"}
                        value ={formState.ubication}
                        component={ComponentsConfig.autocomplete}
                        label = {"*Ciudad de circulación"}
                        validator={useCustomValidator([requiredValidator])}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md = {6}>
                    <Field
                        key={"price_vehicle"}
                        id={"price_vehicle"}
                        name={"price_vehicle"}
                        disabled = { formState.in_agency ? false : true }
                        defaultValue= {formState.price_vehicle || formState.vehicle?.vehicle.vehicle_risk.reference_price}
                        component={ComponentsConfig.number}
                        label = {"*Precio del vehículo"}
                        validator={useCustomValidator([onlyNumbersValidator])}
                    />
                </Col>
            </Row>
        </Container>
    )

}
const defualtData = {
    accesories_price : 0

};

const config = {
    showPrevButton:true,
    showNextButton: true
}

const header = 
{
    label: 'Vehículo',
    icon: 'k-i-file-wrench',
    isValid: undefined
};
  
const PropertiesStep3 = {
    component : Step,
    header : header,
    config: config,
    defualtData: defualtData
};


export { PropertiesStep3 };


