import { useEffect } from "react";
import { getClientIp, getOptionalFieldsConfig } from "../Utils/authenticate";

export default function useGetClientIp(setContext) {
  useEffect(() => {
    getClientIp().then((res) => {
      setContext((context) => {
        return {
          ...context,
          clientIp: res,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
