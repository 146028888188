import * as React from 'react';
import "./Step3.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'


function Step (props) {
    const {formState,setFormState} = props;
    return(
        <React.Fragment >
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Tipo de placa</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.plate_type.text} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Tipo de uso</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.use_type.text} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Valor adicional en accesorios</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.accesories_price?formState.accesories_price:"0"} />
                </Col>
                
            </Row>
            <br></br>
            <Row>
                <Col >
                    <Form.Label>Ciudad de circulación</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={`${formState.ubication.city_name}-${formState.ubication.state_name}`} />
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
                <Col >
                    <Form.Label>Precio del vehículo</Form.Label>
                </Col>
                <Col sm={6} md={7} lg={9}>
                    <Form.Control disabled={true} value={formState.price_vehicle} />
                </Col>
            </Row>
        </React.Fragment>
    )

}
const PropertiesStep3 = {
    component : Step,
};


export { PropertiesStep3 };