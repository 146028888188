const getAsesorMailByUrlParam = (origin) => {
  const url = new URLSearchParams(window.location.search);
  const root = document.getElementById(origin);
  // tomar el mail del asesor si no se encuentra el atributo redirect_to
  const mail = root.getAttribute("asesor") || url.get("asesor");
  // delete all quotes if exist
  const mailWithoutQuotes = mail && mail.replace(/['”"]+/g, "");
  const mailTrimmed = mailWithoutQuotes && mailWithoutQuotes.trim();
  console.log("asesor |" + mailTrimmed + "|");
  return mailTrimmed;
};

export default getAsesorMailByUrlParam;
