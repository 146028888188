import { PropertiesStep1 } from "./Step1"
import { PropertiesStep2 } from "./Step2"
import { PropertiesStep3 } from "./Step3"
import { PropertiesStep4 } from "./Step4"


const stepsToRender = [ 
    PropertiesStep1 , 
    PropertiesStep2 , 
    PropertiesStep3 , 
    PropertiesStep4 ,
]


const stepPagesResume = stepsToRender.map((step)=>(step.component))



export { stepPagesResume }